import { useState, useEffect } from 'react';

import Config from '../config';

const pricesInitials = {
  btc: {
    btc_buy: 0.01,
    btc_sell: 0.01,
  },
  fiat: {
    au: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    ar: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    bo: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    cl: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    co: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    ec: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    mx: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    pa: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    py: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    pe: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    peusd: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    uy: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    ve: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    us: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    uswires: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    gb: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
      cop_sell: 0.01,
      cop_buy: 0.01,
    },
    br: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    cn: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    cr: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    do: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    ht: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    eu: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    causd: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
    cnusd: {
      usd_buy: 0.01,
      usd_sell: 0.01,
      clp_sell: 0.01,
      clp_buy: 0.01,
    },
  },
  days: {
    ar: 2,
    bo: 2,
    ec: 1,
    mx: 3,
    pa: 2,
    py: 2,
    pe: 1,
    peusd: 2,
    uy: 2,
    cl: 1,
    ve: 2,
    co: 1,
    us: 3,
    uswires: 3,
    gb: 3,
    br: 2,
    cn: 2,
    cr: 2,
    do: 2,
    ht: 2,
    eu: 3,
  },
  messageEstimatedTime: {
    ar: '',
    bo: '',
    ec: '',
    mx: '',
    pa: '',
    py: '',
    pe: '',
    peus: '',
    uy: '',
    cl: '',
    ve: '',
    co: '',
    us: '',
    gb: '',
    br: '',
    cn: '',
    cr: '',
    do: '',
    ht: '',
    eu: '',
  },
  valid_until: null,
  fiat_withdrawal_fee: 0,
};

const useTransferPrices = () => {
  const [hookPrices, setHookPrices] = useState(pricesInitials);
  const [isReadyPrices, setReadyPrices] = useState(false);
  const [errorFetchMessage, setErrorFetchMessage] = useState('');
  const { usePricesContext } = Config.getInstance().getConfiguration();
  const {
    pricesTransferIsReady,
    prices: {
      transferPrices: { prices },
      fetchError,
    },
    getTransferPricesContext,
  } = usePricesContext();

  useEffect(() => {
    if (pricesTransferIsReady && Object.keys(prices).length > 0) {
      setHookPrices(prices);
      setErrorFetchMessage(fetchError);
      setReadyPrices(true);
    } else {
      setReadyPrices(false);
    }
  }, [prices, pricesTransferIsReady, fetchError]);

  return {
    prices: hookPrices,
    errorFetch: errorFetchMessage,
    isReadyPrices,
    getTransferPricesContext,
  };
};

export default useTransferPrices;
