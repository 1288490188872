import React from 'react';
import PropTypes from 'prop-types';
import * as Animatable from 'react-native-animatable';
import { View, Image } from 'react-native';

import useWindow from 'wallet/hooks/useWindow';
import TextField, { MAX_SIZE, EXTRA_NORMAL_SIZE } from '../TextField';

import { Assets } from 'alejandra/resources';
import { Assets as AssetsW, flexCenter } from '../../../resources';

import styles from './styles';

import {
  LOADING_CONTENT_CHECKOUT,
  LOADING_CONTENT_DEFAULT,
  LOADING_CONTENT_INFORMATION,
} from './util';

const CONTENT_LOADING = {
  [LOADING_CONTENT_DEFAULT]: {
    image: AssetsW.images.informationHelper,
    title: '¡Estamos procesando tu solicitud!',
    content: 'Este proceso puede tardar algunos\nminutos.',
  },
  [LOADING_CONTENT_INFORMATION]: {
    image: AssetsW.images.informationHelper,
    title: '¡Estamos cargando la información!',
    content: 'Este proceso puede tardar algunos minutos, por favor, no cierres la app.',
  },
  [LOADING_CONTENT_CHECKOUT]: {
    image: AssetsW.images.waitLoader,
    title: 'Te estamos redirigiendo al proveedor de pagos',
    content: 'Este proceso puede tardar algunos minutos, no cierres esta ventana.',
  }
};

const Loading = (props) => {
  const {
    isTransparent,
    containerStyle,
    isCenter,
    extraStyle,
    isHelperContent,
    typeHelper,
  } = props;
  const { isWeb } = useWindow();

  return (
    // <Modal transparent>
    <View
      style={
        [
          containerStyle
            ? containerStyle
            : isTransparent
              ? styles.transparent_container
              : styles.container,
          isCenter && { ...flexCenter, paddingTop: 0 },
          extraStyle,
          {
            zIndex: 9999999,
            elevation: 9999999,
          }
        ]
      }
    >
      <View style={isWeb ? styles.image_content_animated : styles.image_content}>
        {/* <Image style={styles.image} source={Assets.images.vitaLoader} /> */}
        <Animatable.View
          animation="swing"
          iterationCount="infinite"
          direction="alternate"
          delay={200}
        >
          <Image style={isWeb ? styles.imageAnimated : styles.image} source={isWeb ? AssetsW.images.vitaLoaderAnimated : Assets.images.vitaLoader} />
        </Animatable.View>
      </View>
      {
        isHelperContent && (
          <View style={styles.content_helper}>
            <Image
              source={CONTENT_LOADING[typeHelper].image}
              style={styles.people_loading}
            />
            <TextField
              text={CONTENT_LOADING[typeHelper].title}
              style={styles.title_helper}
              type={MAX_SIZE}
              isTitle
              isCenter
            />
            <TextField
              text={CONTENT_LOADING[typeHelper].content}
              style={styles.text_helper}
              type={EXTRA_NORMAL_SIZE}
              isCenter
            />
          </View>
        )
      }
    </View>
    // </Modal>
  );
};

Loading.propTypes = {
  isTransparent: PropTypes.bool,
  isCenter: PropTypes.bool,
  isHelperContent: PropTypes.bool,
  containerStyle: PropTypes.any,
  typeHelper: PropTypes.string,
};

Loading.defaultProps = {
  isTransparent: false,
  isCenter: false,
  isHelperContent: false,
  containerStyle: null,
  typeHelper: LOADING_CONTENT_DEFAULT,
};

export default Loading;
