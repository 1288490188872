import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const signUsdAccount = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.usdAccount.signUsdAccount(data),
    headers,
  });
};

const requestUpdateProfile = (headers) => {
  return apiCall({
    ...ENDPOINTS.usdAccount.requestUpdateProfile,
    headers,
  });
};

export default {
  signUsdAccount,
  requestUpdateProfile
};
