import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const TIME_LOT = 'time-lot';
const HAS_SESION_BY_LOT = 'has-session-by-lot';

const LOTS_KEY = 'LOTS_INFO'
const LOTS_DEFAULT_VALUE = {
  [TIME_LOT]: 0,
  [HAS_SESION_BY_LOT]: false,
};

const businessLots = (useStorage) => {
  const BusinessLotsContext = createContext({
    informationLots: LOTS_DEFAULT_VALUE,
    updateInformationLots: () => {},
    getInformationLots: () => {},
    lotsIsReady: false,
  });
  const { Provider } = BusinessLotsContext;

  const BusinessLotsProvider = (props) => {

    const [informationLots, updateInformationLots, hydrated, getInformationLots] = useStorage(
      LOTS_KEY,
      LOTS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        informationLots,
        updateInformationLots,
        getInformationLots,
        lotsIsReady: hydrated,
      }),
      [informationLots, updateInformationLots, hydrated, getInformationLots]
    );
    return <Provider value={value} {...props} />;
  }

  BusinessLotsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useBusinessLots = () => {
    const context = useContext(BusinessLotsContext);

    if (!context) {
      throw new Error('useBusinessLots must be used within a businessLots');
    }

    const {informationLots, updateInformationLots, lotsIsReady, getInformationLots, ...rest} = context;

    const cleanInfoLots = useCallback(() => {
      updateInformationLots({
        ...LOTS_DEFAULT_VALUE,
      });
    }, [updateInformationLots]);

    const updateSesionByLot = useCallback((value) => {
      updateInformationLots({
        ...informationLots,
        [HAS_SESION_BY_LOT]: true,
        [TIME_LOT]: value,
      });
    }, [updateInformationLots, informationLots]);

    const getSesionByLot = useCallback(() => {
      return getInformationLots(HAS_SESION_BY_LOT);
    }, [getInformationLots]);


    return {
      ...rest,
      informationLots,
      cleanInfoLots,
      getSesionByLot,
      updateSesionByLot,
    }
  }

  return {
    BusinessLotsProvider,
    useBusinessLots,
  };
};

export default businessLots;
