import React, {createContext, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import {SIDEBAR_DEFAULT_VALUE, SIDEBAR_KEY} from './state';

const sidebar = (useStorage) => {
  const SidebarContext = createContext({
    sidebar: SIDEBAR_DEFAULT_VALUE,
    updateSidebar: () => {},
    sidebarIsReady: false,
  });
  const {Provider} = SidebarContext;

  const SidebarProvider = (props) => {
    const [sidebar, updateSidebar, hydrated] = useStorage(
      SIDEBAR_KEY,
      SIDEBAR_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        sidebar,
        updateSidebar,
        sidebarIsReady: hydrated,
      }),
      [sidebar, updateSidebar, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  SidebarProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useSidebar = () => {
    const context = useContext(SidebarContext);

    if (!context) {
      throw new Error('useSidebar must be used within a SidebarProvider');
    }

    const {sidebar, updateSidebar: update, ...rest} = context;

    const updateSidebar = useCallback(
      (newValue) => {
        update({
          ...sidebar,
          ...newValue,
        });
      },
      [sidebar, update],
    );

    const cleanSidebar = () => {
      update(SIDEBAR_DEFAULT_VALUE);
    };

    const openSideBarMenu = useCallback(() => {
      updateSidebar({
        ...sidebar,
        isCollapsedMenu: false,
      });
    }, [sidebar, updateSidebar]);

    const hiddenSideBarMenu = useCallback(() => {
      updateSidebar({
        ...sidebar,
        isCollapsedMenu: true,
      });
    }, [sidebar, updateSidebar]);

    const openSettingsMenu = useCallback(() => {
      updateSidebar({
        ...sidebar,
        isSettingsMenu: true,
      });
    }, [sidebar, updateSidebar]);

    const hiddenSettingsMenu = useCallback(() => {
      updateSidebar({
        ...sidebar,
        isSettingsMenu: false,
      });
    }, [sidebar, updateSidebar]);

    return {
      ...rest,
      sidebar,
      cleanSidebar,
      openSideBarMenu,
      hiddenSideBarMenu,
      openSettingsMenu,
      hiddenSettingsMenu,
    };
  };

  return {
    SidebarProvider,
    useSidebar,
  };
};

export default sidebar;
