import {apiCall} from '../../middlewares';
import {ENDPOINTS} from '../../config/constants';

const saveBiometricPublickey = (data, headers) => {
  return apiCall({
    ...ENDPOINTS.biometric.saveBiometricPublickey(data),
    headers,
  });
};

const deleteBiometricPublickey = (data, headers) => {
  return apiCall({
    ...ENDPOINTS.biometric.deleteBiometricPublickey(data),
    headers,
  });
};

export default {
  saveBiometricPublickey,
  deleteBiometricPublickey,
};
