import {DEEP_LINKING_PREFIXE} from '../config/constants';

export const UTM_PARAMS = 'UTM_PARAMS';

export const configurationDeepLinking = {
  prefixes: [DEEP_LINKING_PREFIXE],
  config: {
    Authentication: {
      path: '',
      screens: {
        RecoverPinAccessScene: {
          path: 'recover_pin',
        },
        PaymentGateway: {
          path: 'payment-gateway/:token',
        },
        PaymentVoucher: {
          path: 'voucher',
        },
        SingleUseCode: {
          path: 'single-use-code',
        },
        SignInScene: {
          path: 'register',
        },
        LogInScene: {
          path: 'login',
        },
        MenuScene: {
          path: 'menu',
        },
        BusinessCredentials: {
          path: 'business_credentials',
        },
      },
    },
  },
};