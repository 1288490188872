import React, {createContext, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import {ALERTS_DEFAULT_VALUE, ALERTS_KEY} from './state';

const alerts = (useStorage) => {
  const AlertContext = createContext({
    alerts: ALERTS_DEFAULT_VALUE,
    updateAlerts: () => {},
    alertsIsReady: false,
  });
  const {Provider} = AlertContext;

  const AlertsProvider = (props) => {
    const [alerts, updateAlerts, hydrated] = useStorage(
      ALERTS_KEY,
      ALERTS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        alerts,
        updateAlerts,
        alertsIsReady: hydrated,
      }),
      [alerts, updateAlerts, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  AlertsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useAlerts = () => {
    const context = useContext(AlertContext);

    if (!context) {
      throw new Error('useAlerts must be used within a AlertsProvider');
    }

    const {alerts, updateAlerts: update, ...rest} = context;

    const hiddenAlertCoupon = useCallback(() => {
      update({
        coupons: {
          ...alerts.coupons,
          alertCoupon: false,
        },
      });
    }, [alerts, update]);

    const counterAction = useCallback(() => {
      update({
        coupons: {
          ...alerts.coupons,
          counter: alerts.coupons.counter + 1,
        },
      });
    }, [alerts, update]);

    return {
      ...rest,
      alerts,
      hiddenAlertCoupon,
      counterAction,
    };
  };

  return {
    AlertsProvider,
    useAlerts,
  };
};

export default alerts;
