import { useEffect, useState, useCallback, useMemo } from "react";
import useResidenceConfig from './useResidenceConfig';
import { powwiService } from '../services';
import config from "../config";

const usePowwi = (props = {}) => {
  const [code, setCode] = useState(null);
  const [isTerms, setIsTerms] = useState(false);
  const [banksPSE, setBanksPSE] = useState([]);
  const { user } = config.getInstance().getConfiguration().useUser();
  const { getBanksPSE } = powwiService;
  const { size_of_digits_for_sms, iso_code, recharge_providers } = useResidenceConfig();
  const isPse = useMemo(() => recharge_providers?.find(item => item.name === "pse" || item.service_type === "wompi_pse"), [recharge_providers])
  const { scene = '' } = props;

  const settingsPowwi = useMemo(() => {
    return {
      digits: size_of_digits_for_sms,
      minutes: 15,
    }
  }, [size_of_digits_for_sms]);

  const nextStep = useCallback((pin) => {
    setCode(pin);
    setIsTerms(true);
  }, [setIsTerms, setCode]);

  useEffect(() => {
    if (user && scene === 'Recharge' && iso_code === 'CO' && isPse) {
      (async () => {
        const response = await getBanksPSE(user.headers);
        setBanksPSE(response.data);
      })();
    }
  }, [scene]);

  return {
    settingsPowwi,
    code,
    isTerms,
    nextStep,
    setCode,
    banksPSE,
  };
};

export default usePowwi;